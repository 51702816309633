<template>
  <div class="manual-detail row">
    <div class="col-12 vod-top-layer">
      {{ title }}
    </div>
    <div class="col-10 vod-label-layer-vod">
      <div class="player-center">
        <video-player ref="videoPlayer"
          :options="playerOptions"
          @playing="onPlayerStart"
          @play="onPlay"
          @pause="onPause"
          @ended="onPlayerEnded($event)">
        </video-player>
      </div>
    </div>
    <div class="col-2 vod-label-layer">
      <!-- 이수완료 : -->
      <div class="vod-label-div" v-if="isEduEnd">{{$label('LBL0002958')}} {{ isEduEndDate }}</div>
      <!-- 전체 시간 : -->
      <div class="vod-label-div" v-if="!isEduEnd">{{$label('LBL0002959')}} {{ vodTotalDuration }}</div>
      <!-- 재생 시간 : -->
      <div class="vod-label-div" v-if="!isEduEnd">{{$label('LBL0002960')}} {{ vodPlayDuration }}</div>
      <div class="vod-label-div">
        <!-- 처음부터 재생 -->
        <c-btn btnclass="full-width" label="LBL0002961" icon="skip_next" color="orange" @btnClicked="onPlayerPlayFirst" v-if="isFirstPlay && !isPlay && !isEduEnd" />
      </div>
      <div class="vod-label-div">
        <c-btn btnclass="full-width" :label="playLabel" :icon="playIcon" :color="playColor" @btnClicked="onPlayerPlay" v-if="!isEduEnd" />
      </div>
      <div class="vod-label-div">
        <!-- 문제풀이 -->
        <c-btn btnclass="full-width" label="LBL0002962" icon="task_alt" color="teal-6" @btnClicked="questionExplanation" v-if="isEnd && !isPlay && !isEduEnd && !isEduQuestionPass" />
      </div>
      <div class="container signature-border vod-label-div" v-if="isEnd && !isPlay && !isEduEnd && isEduQuestionPass">
        <div class="row items-center all-pointer-events">
          <b>
            <!-- 서명 -->
            <font id="signatureTitle">&nbsp;&nbsp;{{$label('LBL0002921')}}</font>
          </b>
        </div>
        <VueSignaturePad id="signature"  width="90%" height="200px" ref="signaturePad" :options="options"/>
      </div>
      <div class="text-center">
        <q-btn-group outline >
          <!-- 지우기 -->
          <c-btn label="LBL0002952" icon="remove" color="red-6" @btnClicked="onSighRemove" v-if="isEnd && !isPlay && !isEduEnd && isEduQuestionPass" />
          <!-- 교육이수 완료 -->
          <c-btn label="LBL0002953" icon="task_alt" color="teal-6" @btnClicked="eduComplete" v-if="isEnd && !isPlay && !isEduEnd && isEduQuestionPass" />
        </q-btn-group>
      </div>
      <div class="vod-label-div-text">
       <!-- `※ 재생/정지/완료 버튼을<br/>클릭하지 않고 창을 닫을 경우<br/>동영상 재생이력이<br/>저장되지 않습니다.` -->
       {{$label('LBL0002954')}}
       <br/>
       {{$label('LBL0002955')}}
       <br/>
       {{$label('LBL0002956')}}
       <br/>
       {{$label('LBL0002957')}}
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>

import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'education-result-vod-detail',

  props: {
    popupParam: {
      type: Object,
      default: () => ({
        eduEducationId: '',
      }),
    },
  },
  computed: {
    player () {
      return this.$refs.videoPlayer.player
    }
  },
  data() {
    return {
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      isEduQuestionPass: true,
      isEduEnd: false,
      isEduEndDate: '',
      electronSignature: null,
      options: {
        penColor: "#000000",
      },
      isEnd: false,
      isFirstPlay: true,
      isPost: true,
      isInterval: null,
      playDutaionTime: 0,
      isPlay: false,
      playLabel: '재생',
      playColor: 'blue',
      playIcon: 'play_arrow',
      vodTotalDuration: '',
      vodTotalDurationS: 0,
      vodPlayDuration: '',
      title: '',
      playerOptions: {
        autoplay: false,
        controls: true,
        playsinline: true,
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          playToggle: false,
          remainingTimeDisplay: true,
          progressControl: true,
          qualitySelector: true,
        },
        height: (window.innerHeight - 80) + 'px',
      },
      editable: true,
      educationInfo: {
        eduEducationId: '',
        educationName: '',
        eduCourseId: '',
        plantCd: '',
        educationCourseName: '',
        educationKindCdLarge: null,
        educationKindCdSmall: null,
        educationTypeCd: null,
        departmentDeptCd: '',
        departmentDeptName: '',
        executionBudget: '',
        estimatedEducationExpenses: '',
        educationMethodName: '',
        educationMethodCd: null,
        educationOverview: '',
        evaluationEvalCd: null,
        documentStatusCd: '',
        qrCodeRoute: '',
        vod: null,
        regUserId: '',
        chgUserId: '',
        eduSubjectList: [], // 과정별 교육과목
        eduAttendeeList: [],
        eduEvalUserList: [],
        deleteEduEvalUserList: [],
        eduQuestionFlag: 'N',
        eduQuestionMstId: '',
        eduQuestionPassScore: 0,
      },
      getUrl: '',
      insertUrl: '',
      updateUrl: '',
      updateSignatureUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted () {
    // 해당 교육용 동영상정보 호출
    // this.$http.url = this.$format(this.getUrl, this.popupParam.eduEducationId);
    this.$http.url = this.$format(selectConfig.sop.edu.result.get.url, this.$route.query.eduEducationId);
    this.$http.type = 'GET';
    this.$http.request((_result) => {
      this.educationInfo = _result.data;
      this.title = '교육용 동영상 시청 - '+ this.educationInfo.educationName
      if (process.env.VUE_APP_UPLOAD_TYPE == 'AWS') {
        // AWS S3용
        this.$http.url = selectConfig.com.upload.vodDownS3.url;
        this.$http.type = 'GET';
        this.$http.isLoading = false;
        this.$http.param = {
          sysAttachFileId: _result.data.vod
        }
        this.$http.request((_result2) => {
          const src = _result2.data
          this.playVideo(src)
          this.init();
        },);
      } else {
        // DISK 저장용
        const src = process.env.VUE_APP_API_URL + selectConfig.com.upload.vodDown.url + '/' + _result.data.vod;
        this.playVideo(src)
        this.init();
      }

      if (this.educationInfo.eduQuestionFlag == 'Y') {
        this.isEduQuestionPass = false;
      } else {
        this.isEduQuestionPass = true;
      }
    },);
  },
  activated() {
  },
  deactivated() {
    clearInterval(this.isInterval);
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.getUrl = selectConfig.sop.edu.vod.get.url;
      this.insertUrl = transactionConfig.sop.edu.vod.insert.url;
      this.updateUrl = transactionConfig.sop.edu.vod.update.url;
      this.updateSignatureUrl = transactionConfig.sop.edu.plan.update.targetUser.signature.url;
      this.getData();
    },
    onSighRemove() {
      this.$refs.signaturePad.clearSignature();
    },
    getData() {
      if (this.$route.query.eduEducationId) {
        this.$http.url = this.getUrl;
        this.$http.type = 'GET';
        this.$http.isLoading = false;
        this.$http.param = {
          eduEducationId: this.$route.query.eduEducationId,
          attendeesId: this.$store.getters.user.userId,
        }
        this.$http.request((_result) => {
          if (_result.data) {
            this.playDutaionTime = parseFloat(this.playDutaionTime) + parseFloat(_result.data.vodPlayTime);
            this.vodPlayDuration = this.getPlayTime(this.playDutaionTime);
            this.vodTotalDurationS = parseFloat(_result.data.vodTotalTime);
            this.vodTotalDuration = this.getPlayTime(this.vodTotalDurationS);
            if (_result.data.vodCurrentTime) {
              this.player.currentTime(parseFloat(_result.data.vodCurrentTime))
            }
            this.playLabel = this.$comm.getLangLabel('LBL0002963'); // 연속재생
            this.isPost = false;
            this.isFirstPlay = true;

            if ((this.playDutaionTime / this.vodTotalDurationS) * 100 > 50) {
              this.isEnd = true;
            }
            
            if (_result.data.checkCompleteFlag == 'Y') {
              this.isEduEndDate = _result.data.evaluationDate;
              this.isEduEnd = true;
            }
          } else {
            this.isPost = true;
            this.isFirstPlay = false;
          }
        },);
      }
    },
    questionExplanation() {
      if (this.educationInfo.eduQuestionFlag == 'Y') {
        // 문제풀이 대상 교육
        this.openQuiz();
      }
    },
    eduComplete() {
      var data = ''
      if (this.$refs.signaturePad.saveSignature().isEmpty) {
        data = ''
      } else {
        data = this.$refs.signaturePad.saveSignature().data;
      }
      this.electronSignature = data;

      if (!this.electronSignature) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSG0000871', // 서명하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSG0000872', // 교육 이수를 완료하시겠습니까?
          // TODO : 필요시 추가하세요.
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.updateSignatureUrl;
            this.$http.type = 'PUT';
            this.$http.param = {
              electronSignature: this.electronSignature,
              attendeesId: this.$store.getters.user.userId,
              eduEducationId: this.$route.query.eduEducationId,
              regUserId: this.$store.getters.user.userId,
              chgUserId: this.$store.getters.user.userId,
              checkCompleteFlag: 'Y'
            };
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.isEduEnd = true;
              this.getData();
              // this.$emit('closePopup');
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    savePlayTime() {
      if (this.isPost) {
        this.$http.url = this.insertUrl;
        this.$http.type = 'POST';
        this.isPost = false;
      } else {
        this.$http.url = this.updateUrl;
        this.$http.type = 'PUT';
      }
      this.$http.isLoading = false;
      this.$http.param = {
        eduEducationId: this.$route.query.eduEducationId,
        attendeesId: this.$store.getters.user.userId,
        vodTotalTime: this.player.cache_.duration,
        vodPlayTime: this.playDutaionTime,
        vodCurrentTime: this.player.currentTime(),
        regUserId: this.$store.getters.user.userId,
        chgUserId: this.$store.getters.user.userId,
      }
      this.$http.request(() => {
      },);
    },
    // 실제 플레잉되고 있는 경우 초 증가처리 (Video-player 컴포넌트 playing 이벤트)
    onPlayerStart() {
      clearInterval(this.isInterval);
      this.isInterval = setInterval(() => {
        this.playDutaionTime++;
        this.vodPlayDuration = this.getPlayTime(this.playDutaionTime);
      }, 1000);
    },
    onPlay() {
      this.vodTotalDurationS = this.player.cache_.duration;
      this.vodTotalDuration = this.getPlayTime(this.player.cache_.duration);
      this.player.play();
      this.isPlay = true;
      this.playLabel = this.$comm.getLangLabel('LBL0002965'); // 중지
      this.playColor = 'red';
      this.playIcon = 'pause';
    },
    onPause() {
      clearInterval(this.isInterval);
      this.player.pause();
      this.isPlay = false;
      this.playLabel = this.$comm.getLangLabel('LBL0002964'); // 재생
      this.playColor = 'blue';
      this.playIcon = 'play_arrow';

      if ((this.playDutaionTime / this.vodTotalDurationS) * 100 > 50) {
        this.isEnd = true;
      }
      // 중지 시 재생시간 저장
      this.savePlayTime();
    },
    onPlayerPlayFirst() {
      clearInterval(this.isInterval);
      this.player.pause();
      this.isPlay = false;
      this.playLabel = this.$comm.getLangLabel('LBL0002964'); // 재생
      this.playColor = 'blue';
      this.playIcon = 'play_arrow';
      this.isFirstPlay = false;
      this.playDutaionTime = 0;
      this.player.currentTime(0)
      this.onPlayerPlay();
    },
    onPlayerPlay () {
      if (!this.isPlay) {
        this.vodTotalDurationS = this.player.cache_.duration;
        this.vodTotalDuration = this.getPlayTime(this.player.cache_.duration);
        this.player.play();
        this.isPlay = true;
        this.playLabel = this.$comm.getLangLabel('LBL0002965'); // 중지
        this.playColor = 'red';
        this.playIcon = 'pause';
      } else {
        clearInterval(this.isInterval);
        this.player.pause();
        this.isPlay = false;
        this.playLabel = this.$comm.getLangLabel('LBL0002964'); // 재생
        this.playColor = 'blue';
        this.playIcon = 'play_arrow';

        if ((this.playDutaionTime / this.vodTotalDurationS) * 100 > 50) {
          this.isEnd = true;
        }
        // 중지 시 재생시간 저장
        this.savePlayTime();
      }
    },
    onPlayerEnded () {
      clearInterval(this.isInterval);
      this.playDutaionTime = 0;
      this.player.pause();
      this.isPlay = false;
      this.playLabel = this.$comm.getLangLabel('LBL0002964'); // 재생
      this.playColor = 'blue';
      this.playIcon = 'play_arrow';
      this.savePlayTime();
    },
    playVideo: function (source) {
      const video = {
        withCredentials: false,
        type: 'video/mp4',
        src: source
      }
      this.player.reset() // in IE11 (mode IE10) direct usage of src() when <src> is already set, generated errors,
      this.player.src(video)
    },
    getPlayTime(_min) {
      let m = parseInt(_min / 60);
      let s = parseInt(_min % 60);
      return m + '분 ' + s + '초'
    },
    openQuiz() {
      this.popupOptions.title = 'LBL0002966'; // 교육 문제풀이
      this.popupOptions.width = '60%';
      this.popupOptions.param = {
        eduEducationId: this.$route.query.eduEducationId,
        eduQuestionMstId: this.educationInfo.eduQuestionMstId,
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/edu/quiz/solvingQuiz.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeQuizPopup;
    },
    closeQuizPopup(_type) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (_type == 'SUCCESS') {
        this.isEduQuestionPass = true;
      } else {
        this.isEduQuestionPass = false;
      }
    },
  }
};
</script>
<style type="scss">
  .video-js {
    margin: 0 auto !important;
    /* display: contents !important; */
  }
  .player {
    position: absolute !important;
    width: 100%;
    height: 60%;
  }
  .vjs-custom-skin {
    height: 60% !important;
  }

  .vjs-custom-skin /deep/ .video-js {
    height: 60%;
  }

  .vjs-big-play-centerd .vjs-big-play-button {
    display: none !important;
  }

  .signature-border {
    border: 1px solid #888888
  }

  .vod-label-div {
    padding-bottom: 10px;
    font-weight: 600;
  }
  .vod-label-div2 {
    padding-top: 20px;
    padding-bottom: 10px;
  }
  .vod-label-layer {
    border: 1px solid #d8d7d7;
    background-color: #f1f1f1;
    border-radius: 10px;
    padding: 10px !important;
  }
  .vod-label-layer-vod {
    border: 1px solid #d8d7d7;
    background-color: #fdfdfd;
    border-radius: 10px;
    padding: 10px !important;
  }
  .vod-top-layer {
    background-color: #2196f3;
    padding: 10px !important;
    font-size: 1.4em;
    font-weight: 700;
    color: #fff;
  }
  .vod-label-div-text {
    padding-top: 20px;
    font-weight: 600;
    font-size: 1.2em;
    line-height: 30px;
  }
</style>
